/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import jsPDF from 'jspdf'
// import LocalStorageService from './LocalStorage.service'

const invoiceLogo = {
  w: 30,
  h: 25,
}
// const pageData = ''
const fontSizes = {
  HeadTitleFontSize: 18,
  Head2TitleFontSize: 16,
  TitleFontSize: 14,
  SubTitleFontSize: 12,
  NormalFontSize: 10,
  SmallFontSize: 8,
}
const lineSpacing = {
  NormalSpacing: 12,
}
// eslint-disable-next-line import/prefer-default-export
export const generatePDF = (pageData, invoiceInfo, invoiceName) => {
  // const pageData = JSON.parse(LocalStorageService.get('pageData')) || ''

  let pageHeight = 595
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('l', 'pt')
  // eslint-disable-next-line no-nested-ternary
  const firstStartX = 40
  const secondStartX = 310
  const thirdStartX = 570
  const firstCol = 70
  const secondCol = 340
  const thirdCol = 600
  const InitialStartY = 20

  const copies = [1, 2, 3]

  // Invoice iteration
  invoiceInfo.forEach((data, pageIndex) => {
    // Per page copies of invoice
    copies.forEach(invoice => {
      const rightStartCol = invoice === 1 ? firstCol : invoice === 2 ? secondCol : thirdCol
      // eslint-disable-next-line no-nested-ternary
      const startX = invoice === 1 ? firstStartX : invoice === 2 ? secondStartX : thirdStartX
      let startY = 0
      // logo section
      doc.setFontSize(9)
      doc.setFont('helvetica')
      doc.setFont('bold')
      doc.addImage(pageData.favicon, 'JPEG', startX, startY += 25, invoiceLogo.w, invoiceLogo.h)
      const centerLineWidth = pageData.slug.toLowerCase() === 'apbnpsc' ? 200 : 180
      // Side  header content Section
      let tempY = InitialStartY
      doc.setFont('bold')
      doc.text(`${pageData.name}`, rightStartCol + lineCenterWidth(doc, centerLineWidth, pageData.name), tempY += lineSpacing.NormalSpacing)
      doc.setFontSize(8)
      doc.setFont('normal')
      doc.text(`${data.studentData.campus}`, rightStartCol + lineCenterWidth(doc, centerLineWidth, data.studentData.campus), tempY += lineSpacing.NormalSpacing)
      if (data.studentData.campusAddress) {
        doc.text(`${data.studentData.campusAddress || ''}`, rightStartCol + lineCenterWidth(doc, centerLineWidth, data.studentData.campusAddress), tempY += lineSpacing.NormalSpacing)
      }
      const paymentInvoice = `Payment Invoice (${invoice === 1 ? 'Bank copy' : invoice === 2 ? 'School copy' : 'Student copy'}) for ${data.invoiceInfo.paymentCycleName}`
      doc.text(paymentInvoice, rightStartCol + lineCenterWidth(doc, centerLineWidth, paymentInvoice), tempY += lineSpacing.NormalSpacing)
      const lastDate = `Last date of payment (without Fine): ${data.invoiceInfo.paymentLastDate}`
      doc.text(lastDate, rightStartCol + lineCenterWidth(doc, centerLineWidth, lastDate), tempY += lineSpacing.NormalSpacing)

      // eslint-disable-next-line no-nested-ternary
      tempY += 5
      const firstLineX1 = invoice === 1 ? firstStartX : invoice === 2 ? secondStartX : thirdStartX
      const secondLineX1 = invoice === 1 ? firstStartX + 70 : invoice === 2 ? secondStartX + 70 : thirdStartX + 70
      const firstLineX2 = invoice === 1 ? firstStartX + 230 : invoice === 2 ? secondStartX + 230 : thirdStartX + 230
      const secondLineX2 = invoice === 1 ? firstStartX + 140 : invoice === 2 ? secondStartX + 140 : thirdStartX + 140
      const thirdLineX1 = invoice === 1 ? firstStartX + 180 : invoice === 2 ? secondStartX + 180 : thirdStartX + 180
      const addLineY1 = 45
      const addLineY2 = 15
      const addLineY3 = 30

      doc.setDrawColor(19, 18, 18) // draw red lines
      doc.line(firstLineX1, tempY, firstLineX2, tempY) // horizontal line
      doc.line(firstLineX1, tempY + addLineY1, firstLineX2, tempY + addLineY1) // horizontal line
      doc.line(firstLineX1, tempY, firstLineX1, tempY + addLineY1) // first vertical line
      doc.line(firstLineX2, tempY, firstLineX2, tempY + addLineY1) // second vertical line
      doc.line(secondLineX1, tempY, secondLineX1, tempY + addLineY1) // second vertical line
      doc.line(firstLineX1, tempY + addLineY2, firstLineX2, tempY + addLineY2) // horizontal line
      doc.line(secondLineX2, tempY + addLineY2, secondLineX2, tempY + addLineY1) // third vertical line
      doc.line(firstLineX1, tempY + addLineY3, firstLineX2, tempY + addLineY3) // horizontal line
      doc.line(thirdLineX1, tempY + addLineY2, thirdLineX1, tempY + addLineY1) // fifth vertical line

      // eslint-disable-next-line no-nested-ternary
      const stdTextX = invoice === 1 ? firstStartX + 2 : invoice === 2 ? secondStartX + 2 : thirdStartX + 2
      const stdTextXX = invoice === 1 ? firstStartX + 72 : invoice === 2 ? secondStartX + 72 : thirdStartX + 72
      const stdTextXXX = invoice === 1 ? firstStartX + 143 : invoice === 2 ? secondStartX + 143 : thirdStartX + 143
      const stdTextXXXX = invoice === 1 ? firstStartX + 182 : invoice === 2 ? secondStartX + 182 : thirdStartX + 182
      const stdLineSpacing = 15
      let stdTextY = 93

      doc.setFontSize(fontSizes.SmallFontSize)
      doc.text('Student name ', stdTextX, stdTextY)
      doc.text(`${data.studentData.name}`, stdTextXX, stdTextY)
      doc.text('Student Id ', stdTextX, stdTextY += stdLineSpacing)
      doc.text(`${data.studentData.studentId}`, stdTextXX, stdTextY)
      doc.text('Section', stdTextXXX, stdTextY)
      doc.text(`${data.studentData.section}`, stdTextXXXX, stdTextY)
      doc.text('Class', stdTextX, stdTextY += stdLineSpacing)
      doc.text(`${data.studentData.class}`, stdTextXX, stdTextY)
      doc.text('Roll', stdTextXXX, stdTextY)
      doc.text(`${data.studentData.roll}`, stdTextXXXX, stdTextY)

      tempY = stdTextY + 12
      const lineX1 = invoice === 1 ? firstStartX : invoice === 2 ? secondStartX : thirdStartX
      const lineX2 = invoice === 1 ? firstStartX + 230 : invoice === 2 ? secondStartX + 230 : thirdStartX + 230
      const lineX3 = invoice === 1 ? firstStartX + 20 : invoice === 2 ? secondStartX + 20 : thirdStartX + 20
      const lineX4 = invoice === 1 ? firstStartX + 175 : invoice === 2 ? secondStartX + 175 : thirdStartX + 175
      doc.setDrawColor(19, 18, 18) // draw red lines
      doc.line(lineX1, tempY, lineX2, tempY) // horizontal line
      doc.line(lineX1, tempY + 12, lineX2, tempY + 12) // horizontal line
      doc.line(lineX1, tempY, lineX1, tempY + 12) // first vertical line
      doc.line(lineX3, tempY, lineX3, tempY + 12) // second vertical line
      doc.line(lineX4, tempY, lineX4, tempY + 12) // third vertical line
      doc.line(lineX2, tempY, lineX2, tempY + 12) // fourth vertical line

      // Table Header
      const tabelX = invoice === 1 ? firstStartX + 2 : invoice === 2 ? secondStartX + 2 : thirdStartX + 2
      let tableY = 143
      doc.setFont('bold')
      doc.text('SL', tabelX, tableY)
      doc.text('Description', tabelX + 70, tableY)
      doc.text('Amount', tabelX + 180, tableY)

      const tabelBodyX = invoice === 1 ? firstStartX + 2 : invoice === 2 ? secondStartX + 2 : thirdStartX + 2
      let tableBodyY = 155
      const invoiceTablesData = data.invoiceData
      invoiceTablesData.forEach((item, index) => {
        const desLength = item.item_name.length
        let addLineLength = 12
        let lineTope = 0
        if (desLength > 42) {
          addLineLength = 22
          lineTope = 5
        }
        if (desLength > 75) {
          addLineLength = 32
          lineTope = 10
        }

        const description = doc.splitTextToSize(`${item.item_name}`, 150)
        tempY = tableY + 4
        doc.line(lineX1, tempY + addLineLength, lineX2, tempY + addLineLength) // horizontal line
        doc.line(lineX1, tempY, lineX1, tempY + addLineLength) // first vertical line
        doc.line(lineX3, tempY, lineX3, tempY + addLineLength) // second vertical line
        doc.line(lineX4, tempY, lineX4, tempY + addLineLength) // third vertical line
        doc.line(lineX2, tempY, lineX2, tempY + addLineLength) // fourth vertical line
        /// Table data
        doc.text(`${index + 1}`, tabelBodyX, tableBodyY + lineTope)
        doc.text(description, tabelBodyX + 25, tableBodyY)
        doc.text(`${item.amount}`, tabelBodyX + 180, tableBodyY + lineTope)

        tableY += addLineLength
        tableBodyY += addLineLength
      })
      tempY = tableY + 15
      doc.line(lineX1, tempY, lineX2, tempY) // horizontal line
      doc.line(lineX1, tempY - 12, lineX1, tempY) // first vertical line
      doc.line(lineX4, tempY - 12, lineX4, tempY) // third vertical line
      doc.line(lineX2, tempY - 12, lineX2, tempY) // fourth vertical line

      doc.setFont('bold')
      doc.text('Total', tabelBodyX + 80, tableBodyY)
      doc.text(`${data.amountTotal}`, tabelBodyX + 180, tableBodyY)

      startY = stdTextY + 21
      // -------Invoice note---------------------
      let startX1 = invoice === 1 ? firstStartX : invoice === 2 ? secondStartX : thirdStartX
      // startY = doc.lastAutoTable.finalY + 5
      startY = tableBodyY + 5
      doc.setFontSize(fontSizes.SmallFontSize)
      doc.setFont('normal')
      const notes = data.invoiceFooter.note || ''
      if (notes) {
        // let NoteY = startY
        doc.text('Notes:', startX1, startY += lineSpacing.NormalSpacing)

        notes.forEach((note, i) => {
          if (!note.notes) return
          const noteLength = note.notes.length
          const noteDes = doc.splitTextToSize(`${i + 1}. ${note.notes}`, 240)
          doc.text(noteDes, startX1, startY += lineSpacing.NormalSpacing)
          if (noteLength > 70) startY += lineSpacing.NormalSpacing
        })
      }

      // Footer left part
      const startX2 = invoice === 1 ? firstStartX + 50 : invoice === 2 ? secondStartX + 50 : thirdStartX + 50
      startY = 520
      // Printed date
      if (data.invoiceFooter.printedDate) {
        doc.setFontSize(fontSizes.SmallFontSize)
        doc.text('Print Date :', startX1, startY += lineSpacing.NormalSpacing)
        doc.text(`${data.invoiceFooter.printedDate}`, startX2, startY)
      }
      // Printed by
      if (data.invoiceFooter.printedBy) {
        doc.setFontSize(fontSizes.SmallFontSize)
        doc.text('Printed By :', startX1, startY += lineSpacing.NormalSpacing)
        doc.text(`${data.invoiceFooter.printedBy}`, startX2, startY)
      }
      //  Powered by
      if (pageData.powered_by) {
        doc.text('Powered BY :', startX1, startY += lineSpacing.NormalSpacing)
        doc.text(`${pageData.powered_by}`, startX2, startY)
      }

      // Footer right part signature
      startY = 520
      const startY2 = 573
      startX1 = invoice === 1 ? firstStartX + 140 : invoice === 2 ? secondStartX + 140 : thirdStartX + 140
      // doc.setDrawColor(19, 18, 18) // draw red lines
      // doc.setLineWidth(0.5)
      // doc.line(startX1, startY, lineX2, startY) // horizontal line
      // doc.line(startX1, startY, startX1, startY2) // vertical line
      // doc.line(lineX2, startY, lineX2, startY2) // vertical line
      doc.setFontSize(fontSizes.SmallFontSize)

      // Signature testing
      // eslint-disable-next-line global-require
      const signature = require('@/assets/images/signature/signature_2002170002.jpeg')
      // console.log(lineCenterWidth(doc, lineX2 - startX1, data.invoiceFooter.employeeInfo.designation))
      if (data.invoiceFooter.employeeInfo) {
        if (data.invoiceFooter.signature) {
          doc.addImage(signature, 'jpeg', startX1 + 6, startY += lineSpacing.NormalSpacing, 80, 20)
          if (data.invoiceFooter.employeeInfo.designation) {
            doc.text(`${data.invoiceFooter.employeeInfo.designation || ''}`, startX1 + lineCenterWidth(doc, lineX2 - startX1, data.invoiceFooter.employeeInfo.designation), startY += lineSpacing.NormalSpacing + 24)
          }
        // doc.text('(Signature)', startX1 + 25, startY += lineSpacing.NormalSpacing + 24)
        } else {
          doc.text('(Signature)', startX1 + 25, startY += lineSpacing.NormalSpacing)

          // doc.setFont('bold')
          // doc.setFontSize(fontSizes.NormalFontSize)
          if (data.invoiceFooter.employeeInfo.employee_name) {
            doc.text(`${data.invoiceFooter.employeeInfo.employee_name}`, startX1 + lineCenterWidth(doc, lineX2 - startX1, data.invoiceFooter.employeeInfo.employee_name), startY += lineSpacing.NormalSpacing)
          }

          doc.setFontSize(fontSizes.SmallFontSize)
          if (data.invoiceFooter.employeeInfo.designation) {
            doc.text(`${data.invoiceFooter.employeeInfo.designation}`, startX1 + lineCenterWidth(doc, lineX2 - startX1, data.invoiceFooter.employeeInfo.designation), startY += lineSpacing.NormalSpacing)
          }
          if (data.invoiceFooter.employeeInfo.department) {
            doc.text(`${data.invoiceFooter.employeeInfo.department}`, startX1 + lineCenterWidth(doc, lineX2 - startX1, data.invoiceFooter.employeeInfo.department), startY += lineSpacing.NormalSpacing)
          }
        }
      }
      // doc.line(startX1, startY + 5, lineX2, startY + 5) // horizontal line
    })

    // Multiple page
    if (invoiceInfo.length === pageIndex + 1) {
      return
    }
    pageHeight += 595
    if (pageHeight > 595) {
      doc.addPage()
    }
  })

  if (process.env.VUE_APP_ENV === 'local') {
    const string = doc.output('datauristring')
    const embed = `<embed width='100%' height='100%' src='${string}'/>`
    const x = window.open()
    x.document.open()
    x.document.write(embed)
    x.document.close()
    return
  }

  doc.save(`${invoiceName}.pdf`)
}

export const lineCenterWidth = (doc, width, text) => (Number(width / 2)) - (Number(doc.getStringUnitWidth(text)) * Number(Number(doc.internal.getFontSize()) / 2))
